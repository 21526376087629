import axios from "axios";
import { BASE_URL } from "../config/baseUrl";

const USER_URL = "/Auth/resetPassword";

const recoveryEmailService = async (email) => {
  return await axios({
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    url: `${BASE_URL}${USER_URL}?email=${email}`,
  });
};

export default recoveryEmailService;