import axios from "axios";
import { BASE_URL } from "../config/baseUrl";
const POST_PASSOWORD_CHANGE_URL = "/Auth/ChangePassword";

class changePassword {

    getHeader = () => {
      const token = localStorage.getItem("token");
      const header = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      };
      return header;
    };
  
    changeUserResetPasswordValue = async (personId, oldPassword, newPassword) => {
        this.changePasswordPost(personId, oldPassword, newPassword).then((value) =>{
          return(value);
          });
    }

    changePasswordPost =(personId, oldPassword, newPassword) => {
        let dataUser ={};
        dataUser ={
            "idPerson": personId,
            oldPassword,
            newPassword
        }
      return axios({
        method: "post",
        headers: this.getHeader(),
        url: `${BASE_URL}${POST_PASSOWORD_CHANGE_URL}`,
        data: dataUser
      });
    }
}

export default changePassword;
