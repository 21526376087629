const NoFound = () => {
	return (
		<div className='flex flex-wrap items-center justify-center pt-24 md:pt-48'>
			<h1 className='w-full mb-4 text-6xl font-semibold text-center text-green-600'>
				No existe esta pagina
			</h1>
		</div>
	);
};

export default NoFound;
