import { useState } from 'react';

const WelcomeTeacher = () => {
	const [userName] = useState(localStorage.getItem('userName'));
	return (
		<div className='flex flex-wrap items-center justify-center pt-24 md:pt-48'>
			<h1 className='w-full mb-4 text-6xl font-semibold text-center text-green-600'>
				¡Bienvenido Profesor!
			</h1>
			<span className='block text-xl font-semibold'>{userName}</span>
		</div>
	);
};

export default WelcomeTeacher;
