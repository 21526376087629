import axios from "axios";
import { BASE_URL } from "../config/baseUrl";

const URL = "/Filter/";
const URL_TEACHERSESSION = "/Session/getSession";
const URL_STUDENTCALIFICATION = "/Student/getStudentCalification";
const URL_CALIFICATION = "/Session/getCalificationsSessionToAssign";
const URL_LITERAL = "/Session/getCalificationLiteral";
const URL_SETCALIFICATION = "/Session/setQualificationSession";

class Grades {
  getHeader = () => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    };
    return header;
  };

  getLevels = async () => {
    const typeFilter = "levels";
    return axios({
      method: "get",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL}${typeFilter}`,
    });
  };

  getYears = (level) => {
    const typeFilter = "years";
    return axios({
      method: "get",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL}${typeFilter}?level=${level}`,
    });
  };

  getPeriods = (level, year) => {
    const typeFilter = "period";
    return axios({
      method: "get",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL}${typeFilter}?level=${level}&year=${year}`,
    });
  };

  getPeriodsByYear = (year) => {
    const typeFilter = "period";
    return axios({
      method: "get",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL}${typeFilter}?year=${year}`,
    });
  };

  getStudenCalification = async (idPeriod, year) => {
    const personID = localStorage.getItem("userID");
    return axios({
      method: "get",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL_STUDENTCALIFICATION}?IdStudent=${personID}&PeriodId=${idPeriod}&Year=${year}`,
    });
  };

  getTeacherSession = async (idPeriod, year) => {
    const personID = localStorage.getItem("userID");
    return axios({
      method: "get",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL_TEACHERSESSION}?personId=${personID}&idPeriod=${idPeriod}&year=${year}`,
    });
  };

  getGradesCalification = async (gradeID) => {
    return axios({
      method: "get",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL_CALIFICATION}?groupId=${gradeID}`,
    });
  };

  getGradesCalificationLiteral = async (qualification) => {
    return axios({
      method: "get",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL_LITERAL}?qualification=${qualification}`,
    });
  };

  putGradesCalificationLiteral = async (qualifications) => {
    return axios({
      method: "put",
      headers: this.getHeader(),
      url: `${BASE_URL}${URL_SETCALIFICATION}`,
      data: qualifications,
    });
  };
}

export default Grades;
