import { Sidebar } from "flowbite-react";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';

const Aside = () =>{
    const activeCss =
    "flex items-center p-2 text-sm font-normal text-green-600 rounded-l-lg hover:bg-gray-100 active";

  const inactiveCss =
    "flex items-center p-2 text-sm font-normal text-green-600 rounded-l-lg hover:bg-gray-100";
    const userTypeId = localStorage.getItem('setType');
    const [userType, setUserType] = useState(0);

    useEffect(() =>{
      setUserType(userTypeId);
    },[userType]);


    return (
      <Sidebar
      className="fixed hidden w-64 h-screen sm:pt-20 md:block"
      aria-label="Sidebar"
    >
      <div className="h-full pt-16 pl-12 overflow-y-auto border-gray-100">
        <ul className="space-y-0">
          <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? activeCss : inactiveCss)}
          >
            <span className="ml-3">Dashboard</span>
          </NavLink>
          </li>


          {/* Registro */}
          <Sidebar.Collapse
            className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
            label="Registro"
          >
            <li className={(userType === '0') ? 'block': 'hidden' }>
            <NavLink
                      to="/calificaciones"
                      className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                    >
                      <span
                        className="flex-1 ml-3 text-left whitespace-nowrap"
                       
                      >
                        Calificaciones
                      </span>
                      
                    </NavLink>
              </li>
              
                <li className={(userType === '0') ? 'block': 'hidden' }>
                    <NavLink
                      to="/seleccion"
                      className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                    >
                      <span
                        className="flex-1 ml-3 text-left whitespace-nowrap"
                       
                      >
                        Selección
                      </span>
                      
                    </NavLink>
                </li>

                <li className={(userType === '0') ? 'block': 'hidden' }>
                    <NavLink
                      to="/selection-panel"
                      className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                    >
                      <span
                        className="flex-1 ml-3 text-left whitespace-nowrap"
                        sidebar-toggle-item=""
                      >
                        Gestionar selección
                      </span>
                      
                    </NavLink>
                </li>
              
            
            
              <li className={(userType === '1') ? 'block': 'hidden' }>
              <NavLink
                  to="/calificaciones-profesor"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                  aria-controls="dropdown-example"
                  data-collapse-toggle="dropdown-example"
                >
                  <span
                    className="flex-1 text-left whitespace-nowrap"
                   
                  >
                    Ingreso calificaciones
                  </span>
                  
                </NavLink>
              </li>
            
            
          </Sidebar.Collapse>
          
          
          {/* REcursos humanos */}
          <div className={(userType !== 0)? 'hidden': 'block' }>
            <Sidebar.Collapse
              className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
              label="Recursos Humanos"
            >
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 1
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 2
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 3
                </a>
              </li>
            </Sidebar.Collapse>
          </div>
          
          {/* Finanzas */}
          <div className={(userType !== 0)? 'hidden': 'block' }>
            <Sidebar.Collapse
              className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
              label="Finanzas"
            >
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 1
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 2
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 3
                </a>
              </li>
            </Sidebar.Collapse>
          </div>
         
          {/* Contabilidad */}
          <div className={(userType !== 0)? 'hidden': 'block' }>
            <Sidebar.Collapse
              className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
              label="Contabilidad"
            >
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 1
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 2
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 3
                </a>
              </li>
            </Sidebar.Collapse>
          </div>
          
          {/* Gestion Academica */}
          <div className={(userType !== 0)? 'hidden': 'block' }>
            <Sidebar.Collapse
              className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
              label="Gestión académica"
            >
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 1
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 2
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 3
                </a>
              </li>
            </Sidebar.Collapse>
          </div>
          
          {/* Calificaciones */}
          <div className={(userType !== 0)? 'hidden': 'block' }>
            <Sidebar.Collapse
              className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
              label="Calificaciones"
            >
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 1
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 2
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 3
                </a>
              </li>
            </Sidebar.Collapse>
          </div>
          

          {/* Administracion */}
          <div className={(userType !== 0)? 'hidden': 'block' }>
            <Sidebar.Collapse
              className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
              label="Administración"
            >
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 1
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 2
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 3
                </a>
              </li>
            </Sidebar.Collapse>
          </div>
          
          {/* Docentes */}
          <div className={(userType !== 0)? 'hidden': 'block' }>
            <Sidebar.Collapse
              className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
              label="Docente"
            >
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 1
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 2
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                >
                  Elemento 3
                </a>
              </li>
            </Sidebar.Collapse>
          </div>
          
          {/* Cobros */}
          <div className={(userType === '0') ? 'block': 'hidden' }>
            <Sidebar.Collapse
                className="flex items-center w-full p-2 text-sm font-normal text-green-600 transition duration-75 rounded-l-lg group hover:bg-gray-100 "
                label="Cobros"
              >
                <NavLink
                  to="/estado-cuenta"
                  className="flex items-center w-full px-2 py-1 text-sm font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                  aria-controls="dropdown-example"
                  data-collapse-toggle="dropdown-example"
                >
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    
                  >
                    Estado de Cuenta
                  </span>
                  
                </NavLink>
            </Sidebar.Collapse>
          </div>
          
        </ul>
      </div>
    </Sidebar>
    );
}

export default Aside;