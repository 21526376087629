import axios from "axios";
import { BASE_URL } from "../config/baseUrl";

const LOGIN_URL = "/Auth/studentAuthenticate";
const loginService = (user, pwd) => {
  return axios({
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    url: `${BASE_URL}${LOGIN_URL}`,
    data: {
      userName: user,
      password: pwd,
    },
  });
};

export default loginService;
