import axios from "axios";
import { BASE_URL } from "../config/baseUrl";

const USER_URL = "/User/getUser";

const getUSerData = async (userID, token) => {
  return await axios({
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
    url: `${BASE_URL}${USER_URL}?id=${userID}`,
  });
};

export default getUSerData;
