import { useState, useEffect } from 'react';
import recoveryEmailService from '../services/recoveryEmail.services';

const ModalRecoveryPassword = () =>{
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

    useEffect(() => {
		setErrMsg('');
	}, [email]);

    const handleSubmit = async e => {
		e.preventDefault();
		 setLoading(true);
        
		try {
      if(email === ''){
        setErrMsg('Debes inresar un correo');
        setLoading(false);
      }else{
        recoveryEmailService(email).then(value => {
          console.log(value);
  if(value?.data?.statusCode === 400){
              setErrMsg(value?.data?.data);
              setLoading(false);
          }
          if(value?.data?.statusCode===200){                 
              setSuccessMsg('Correo enviado!');
              setLoading(false);
              setTimeout(() => {
                  setSuccessMsg('');
                  setShowModal(false)
                }, 1500);
          }
  
});
      }
			
	} catch (err) {
        if (!err?.response) {
            setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Login Failed');
        }
		 setLoading(false);
        setShowModal(false);
    }

		
	};



  return (
    <div>
      <button
        className="block mt-4 text-gray-500 text-md hover:text-blue-500"
        type="button"
        onClick={() => setShowModal(true)}
      >
        ¿Olvidaste tu contraseña?
      </button>
      {showModal ? (
        <div>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
          >
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                  <h3 className="text-3xl font-semibold">
                    Recuperar contraseña
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
            
                <div className="relative flex-auto p-6">
                  <p className="my-4 text-lg leading-relaxed text-slate-500">
                  Enviaremos un correo a su dirección registrada con una nueva contraseña; asegúrese de poseer acceso a dicha bandeja de correo. Favor ingrese su correo el cual debe ser igual al que poseemos registrado en sistema.
                  </p>
                  <div className='w-full'>
						
                            <input
								type='text'
								id='username'
								
								autoComplete='off'
								onChange={e => setEmail(e.target.value)}
								value={email}
								required
								placeholder='Correo Electronico'
								className='w-full px-4 py-2 bg-gray-200 rounded-2xl'
							/>
                        
                            
					</div>
                </div>
        
                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}

                  >
                    No
                  </button>
                  <button
                    className="px-10 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
							<svg
								className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300'
								viewBox='0 0 100 101'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
									fill='currentColor'
								/>
								<path
									d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
									fill='currentFill'
								/>
							</svg>
						) : (
							' Si'
						)}
                  </button>

                  
                
                </div>
                
                <div
                        className={
                            errMsg
                                ? 'bottom-0 left-0 flex justify-center w-full'
                                : 'invisible'
                        }
                        role='alert'
                    >
                        <div
                            className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'
                            role='alert'
                        >
                            <span className='font-medium'>{errMsg}</span>
                        </div>
                    </div>
                    <div
                        className={
                            successMsg
                                ? 'absolute bottom-0 left-0 flex justify-center w-full'
                                : 'invisible'
                        }
                        role='alert'
                    >
                        <div
                            className='p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800'
                            role='alert'
                        >
                            <span className='font-medium'>{successMsg}</span>
                        </div>
                    </div>

              </div>

              

            </div>


                    

                    

          </div>
          
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </div>
      ) : null}
    </div>
  );
}

export default ModalRecoveryPassword;