import { useState, useEffect } from 'react';
import { EyeIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PreselectionService from '../services/preselection.service';
import GradeService from '../services/grades.services';
import RealMockApi from '../api/realMockApi';
import moment from 'moment/moment';
import SingleLoading from '../components/SingleLoading';
import SweetAlertModal from '../components/SweetAlert';
import {useQuery} from '@tanstack/react-query';



const Preselection = () =>{
   const MySwal = withReactContent(Swal);
   const [sessionArray, setSessionArray] = useState([]);
   const realMockApi = new RealMockApi();
   const [open, setOpen] = useState();
   const [enrollment,setEnrollment] = useState("");
   const [periods,setPeriod] = useState([]);
   const [name,setName] = useState("");
   const [currentUserId,setUserId] = useState("");
   const [selectedYear, setSelected] = useState();
   const [currentYear, setCurrentYear] = useState();
   const [selectedPeriod,setSelectedPeriod] = useState();
   const [availableCredit, setAvailableCredit] = useState(24);
   const sweetAlertModal = new SweetAlertModal();
   const [scheduleSelected,setScheduleSelected] = useState([]);

   const [loading, setLoading] = useState(false);
   const [loadingSave, setLoadingSave] = useState(false);

   const [pensumType,setPensumType] = useState("");


   const preselectionService = new PreselectionService();
   const gradeService = new GradeService();

   const [userPreselection, setUserPreselection] = useState([]);
   // const [Session, setSession] = useState([]);

   const [selectionActive, setSelectionActive] = useState(false);

   const {isLoading, data} = useQuery(
      [ 'sessions', open, selectedPeriod, selectedYear ],
      () => preselectionService.getActiveSessions(open, selectedPeriod, selectedYear),
      {
         enabled:!!open,
      }
   )

   useEffect(()=>{
      setEnrollment(localStorage.getItem("userIdLoggin"));
      setName(localStorage.getItem("userName"));
      setUserId(localStorage.getItem("userID"));
      setCurrentYear(new Date().getFullYear());
      setScheduleSelected([]);
      setUserPreselection([]);
      const getPreselectionDate = async () =>{
         setLoading(true);
         preselectionService.validatePreselectionDate().then((value)=>{
            const today = new Date();
            const rangeDates=value?.data?.data;
            const fechaInicio = new Date(rangeDates[0]?.fechaInicio);
            const fechaFin = new Date(rangeDates[0]?.fechaFin);
            if (today >= fechaInicio && today <= fechaFin) {
               setSelectionActive(true);
            }
            setLoading(false);
         });
       }   
       getPreselectionDate();
   },[]);

   const findPeriods = event => {
      setSelected(event.target.value);
      if(event.target.value === "#"){
         setPeriod([]);
      }
      gradeService.getPeriodsByYear(event.target.value).then((value) => {
         setPeriod(value.data.data);
         const data = value.data.data;
         setPeriod(data);
      });
   };

   const findSubjects = async () => {
      // let validation=true;
      setLoading(true);
      preselectionService.getPreselection(currentUserId, selectedPeriod, selectedYear).then(async (value) =>{
         preselectionService.getReglaPensum(currentUserId).then(async (values) =>{
            const datas = values.data.data;
            setPensumType(datas?.tipoRegla);
            setAvailableCredit(datas?.maximoValido);
            const data = value.data.data
            setUserPreselection(data);
            setLoading(false);
            console.log(datas);
      });
   })};
   
   const handleSelectedPeriod = (value) => {
      setSelectedPeriod(event.target.value);
   };

  const handleOpen = (value) => {
     setOpen(open === value ? '' : value);
  };

   function convertToStandardTime(input) {
      if(input === "" || input === null || input === undefined){
         return ""
      }
      if(parseInt(input) < 1000){
         if(input.length === 3){
            input = input.substring(0, 1)+":"+input.substring(1)+"AM"
            return input;
         }else{
            input = input.substring(0, 2)+":"+input.substring(2)+"AM"
            return input;
         }

      }
      return moment(input, 'HH:mm:ss').format('h:mm:ss A');
  }

      const getElementArrayPosition = (arrElement) => {
         const position = sessionArray.findIndex(index =>
            index.subject === arrElement);
         return position;
      }

      const updateArrayValue = (subjectId, groupId)  =>{
         const startPosition = getElementArrayPosition(subjectId)
         sessionArray.splice(startPosition, 1)
      }

      function addScheduleList(isChecked,schedule,subjectId,fromDate,toDate) {
         for (const [key, value] of Object.entries(schedule)){
            if(isChecked){
               scheduleSelected[subjectId]={
                     subjectId,
                     'day':key,
                     'startTime':value.startTime,
                     'endTime':value.endTime,
                     'startDate':fromDate,
                     'endDate':toDate
                  }
               }
            else{
               delete scheduleSelected[subjectId];
            }
         }
      }


      function formatDate(date){
         // Parse the input date string in the format "MM/DD/YYYY"
         const parts = date.split("/");
         const year = parseInt(parts[2], 10);
         const month = parseInt(parts[0], 10) - 1; // month is 0-indexed
         const day = parseInt(parts[1], 10);
         const inputDate = new Date(year, month, day);
       
         // Format the date as "YYYY/MM/DD"
         const formattedDate = inputDate.getFullYear() + "/" +
                               (inputDate.getMonth() + 1).toString().padStart(2, "0") + "/" +
                               inputDate.getDate().toString().padStart(2, "0");
       
         // Return the formatted date as a Unix timestamp (in seconds)
         return Math.round(inputDate.getTime() / 1000);
       }

       function formatingDate(date){
         // Parse the input date string in the format "MM/DD/YYYY"
         const parts = date.split("/");
         const year = parseInt(parts[2], 10);
         const month = parseInt(parts[0], 10) - 1; // month is 0-indexed
         const day = parseInt(parts[1], 10);
         const inputDate = new Date(year, month, day);
       
         // Format the date as "DD/MM/YYYY"
         const formattedDate = inputDate.getDate().toString().padStart(2, "0") + "/" +
                               (inputDate.getMonth() + 1).toString().padStart(2, "0") + "/" +
                               inputDate.getFullYear();
       
         return formattedDate;
       }

      function validateSchedule(isChecked,schedule,fromDate,toDate){
         for (const [key,value] of Object.entries(schedule)){
            if(isChecked){
               for (const [keys,values] of Object.entries(scheduleSelected)){
                  console.log(key, keys,value,values);
                  const StartA = formatDate(fromDate);
                  const EndA = formatDate(toDate);
                  const StartB = formatDate(values.startDate);
                  const EndB = formatDate(values.endDate);

                  if((StartA <= EndB) && (EndA >= StartB)){
                     if(value.day === values.day){
                        const startTimeA=values.startTime;
                        const endTimeA=values.endTime-1;
                        const startTimeB=value.startTime;
                        const endTimeB=value.endTime-1;
                        console.log(startTimeA,endTimeB,endTimeA,startTimeB);
                        if((startTimeA <= endTimeB) && (endTimeA >= startTimeB)){
                           return false;
                        }
                     }
                  }
               }
            }
         }
         return true;
      }

      function addGroupsId(subjectId, groupId, credit, isChecked,schedule,quota,fromDate,toDate) {
         let ac = 0;
         const position = sessionArray.findIndex(index =>
            index.subject === subjectId && index.group === groupId);
            if(!isChecked){
               delete scheduleSelected[subjectId];
               }
            if (position >= 0){
               if(pensumType===1){
                  ac = availableCredit + credit;
               }
               else{
                  ac = availableCredit + 1;
               }
               setAvailableCredit(ac)
               updateArrayValue(subjectId, groupId);
            }else{
               const position1 = sessionArray.findIndex(index =>
                  index.subject === subjectId);
                  if (position1 >= 0){
                     Swal.fire(   'Información',   'Ya tiene una sesión seleccionada para esta materia.',   'error' )
                     document.getElementById(groupId).checked=false;
                  }
                  else if(!validateSchedule(isChecked,schedule,fromDate,toDate)){
                     Swal.fire(   'Información',   'Conflicto con selección previa, por favor validar.',   'error' )
                     document.getElementById(groupId).checked=false;
                  }
                  else if(quota<1){
                     Swal.fire({
                        title:'Sesión sin cupos disponibles', 
                        icon:'error',
                        confirmButtonColor: '#16a34a',
                        confirmButtonText: 'Cerrar'
                     })
                     document.getElementById(groupId).checked=false;
                  }
                  else{
                     let message;
                     if(pensumType===1){
                        ac = availableCredit - credit;
                        message='Excediste la cantidad máxima de créditos por selección';
                     }
                     else{
                        ac = availableCredit - 1;
                        message='Excediste la cantidad máxima de materias por selección';
                     }
                     if(ac < 0){
                        Swal.fire(   'Información',   message,   'error' )
                        document.getElementById(groupId).checked=false;
                     }else{
                        addScheduleList(isChecked,schedule,subjectId,fromDate,toDate);
                        sessionArray.push({subject: subjectId, group: groupId, checkStatus: true,})
                        setAvailableCredit(ac)
                     }
                  }
            }
      }


      async function handleSave(e){
         const groupIds = [];
         sessionArray.forEach(function(obj){
            groupIds.push(obj.group);
         })
         // eslint-disable-next-line
         debugger;
         if(sessionArray.length <=0){
            Swal.fire({
               title:'Debes seleccionar al menos una sesión.', 
               icon:'error',
               confirmButtonColor: '#16a34a',
               confirmButtonText: 'Cerrar'
            })
         }else{
            setLoadingSave(true);
            sweetAlertModal.executeConfirmSelection({mensaje:'¿Deseas continuar?', title:'!Guardando cambios!',currentUserId,groupIds}).then((value) =>{
               console.log(value);
               setLoadingSave(false);
               if(value?.data ==='Ya tiene preseleccion en este periodo' || value?.data?.data===true){
                  setScheduleSelected([]);
                  setUserPreselection([]);
                  setSessionArray([]);
                  setOpen(false);
               }
            });
         }
         
      };

      function handleCancel(e){
         MySwal.fire({
            html: <b>¿Deseas cancelar la preselección?</b>,
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#16a34a',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            }).then(async (result) => {
               if (result.isConfirmed) {
                  Swal.fire({
                     title:'¡Preselección cancelada!', 
                     icon:'success',
                     confirmButtonColor: '#16a34a',
                     confirmButtonText: 'Cerrar'
                  });
                  setScheduleSelected([]);
                  setUserPreselection([]);
                  setSessionArray([]);
                  setOpen(false);
               }
            })
      };

      function validateSession(itemId){
         return sessionArray.find((items) =>{
            return items.group===itemId;
         })
      }
      

   return (
      <div className="pt-0 sm:pt-10">
      {
      loading ? (
         <div className="grid h-64 justify-items-center content-end">
         <SingleLoading className="px-40 py-6"/>
         </div>                                                      
      ): (
      <div className="pt-0 sm:pt-10 bg-red">
         {
            !selectionActive? (
               <div className="grid h-64 justify-items-center content-end">
                  <h1 className="text-3xl font-semibold text-red-600 ">Proceso de selección cerrado</h1>
                  <div className="px-40 py-6">
                     <LockClosedIcon className="h-14 text-red-600 font-extralight text-md" />
                  </div>
               </div>
            ):(
         <div className="p-12">
            {/* {groupIds} */}
            <h1 className="mb-0 text-xl font-semibold text-green-600">Preselección</h1>
            <span className="mb-0 text-s t-16 font-semibold text-red-600">Por favor revisa tu preselección antes de guardar. Una vez guardada deberás esperar la fecha de reajuste para verla, consultarla y editar.</span>
            <div className="grid grid-cols-1 gap-4 pt-6 mb-12 bg-white lg:grid-cols-4">
               <div className="">
                  <label  className="block mb-2 ml-5 text-sm font-medium text-gray-900">Matrícula</label>
                  <input id="matricula" type="text" placeholder="Matrícula" className="w-full px-4 py-2 border-2 rounded-2xl bg-gray-50" value={enrollment} disabled />
               </div>
               <div className="">
                  <label  className="block mb-2 ml-5 text-sm font-medium text-gray-900">Nombre</label>
                  <input id="nombre" type="text" placeholder="Nombre" className="w-full px-4 py-2 border-2 rounded-2xl bg-gray-50" value={name} disabled />
               </div>
               <div className="">
                  <label className="block mb-2 ml-5 text-sm font-medium text-gray-900">Año</label>
                  <select value={selectedYear} onChange={findPeriods} id="year" className="w-full px-4 py-2 border-2 rounded-2xl bg-gray-50">
                     <option value="#">Seleccione</option>
                     <option value={parseInt(currentYear)}>{currentYear}</option>
                     <option value={parseInt(currentYear)+1}>{currentYear+1}</option>
                  </select>
               </div>
               <div className="">
                  <label className="block mb-2 ml-5 text-sm font-medium text-gray-900">Periodo</label>
                  <select id="periodo" value={selectedPeriod} onChange={handleSelectedPeriod} className="w-full px-4 py-2 border-2 rounded-2xl bg-gray-50">
                  <option value="#">Seleccione</option>
                     {
                        periods.map((value) => {
                           return <>
                           <option value={value.id}>{value.name}</option>
                           </>
                        })
                     }
                  </select>
               </div>
               <div className="">
                  <button onClick={findSubjects} className="px-4 py-2 font-semibold text-white uppercase bg-green-600 rounded-full shadow-lg cursor-pointer hover:bg-green-700">
                  {loading ? (
                <svg
                  className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
              ) : (
                'Buscar'
              )}</button>
               </div>

            </div>
            <span className="block mb-2 text-sm text-right">
               {
                  userPreselection.length > 0 &&  
                  <>
                     {
                     pensumType===1 ? ('Total de creditos disponibles') : ('Total de materias disponibles')
                     }: <b>{availableCredit}</b>
                  </>
               }
            </span>
            <div className="relative overflow-x-auto border sm:rounded-lg">
               <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-green-600">
                     <tr>
                     {
                           realMockApi.titleTable().map((value,index)=> (
                              <th key={index} scope="col" className="px-6 py-3 border-r">
                                 {value}
                              </th>
                           ))
                        }
                     </tr>
                  </thead>
                  <tbody>
                     
                     {
                        userPreselection.length <= 0 ?  (
                           
                           <tr>
                              <td colSpan={5} className='py-5 text-center font-bold'>
                              No hay materias que mostrar
                              </td>
                           </tr>

                        ):  (

                           userPreselection.map((value) => {
                           return <>
                              <tr key={value.id} className="font-bold bg-white border-t border-gray-200">
                                    <td className="px-6 py-2">{value.subjectCode}</td>
                                    <td className="px-6 py-2">{value.subjectName}</td>
   
                                    <td className="px-6 py-2">{value.credit}</td>
                                    <td className="px-6 py-2">{
                                    value.requirements.map((value)=>{
                                       return <>{value}<br/></>
                                    })}</td>
                                    
                                    <td className="px-6 py-2">
                                       <EyeIcon onClick={() => handleOpen(value.subjectId)} className="h-6 text-green-600 font-extralight text-md" />
                                    </td>
                                 </tr>
                                 {
                                    open === value.subjectId &&
                                    <tr className="px-5 bg-white border-t border-gray-200">
                                       <td className="" colSpan="6">
                                          <div className="px-10 pt-2 pb-10 bg-green-100">
                                             <table border="5px"  className="w-full mb-2 shadow-lg">
                                                <thead className="text-xs text-white bg-gray-500">
                                                   <tr>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Seleccionada
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Sesión
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Cupos
                                                      </th>
                                                      {/* <th scope="col" className="px-6 py-2 border-r">
                                                         Recinto
                                                      </th> */}
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Fecha inicio
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Fecha fin
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Aula
                                                      </th>
                                                      
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Lun
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Mar
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Mie
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Jue
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Vie
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Sab
                                                      </th>
                                                      
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   
                                                   {


                                                      isLoading ? (
                                                         <SingleLoading/>                                                         
                                                      ):
                                                      
                                                      data.data.data.length <=0 ?  (
               
                                                         <tr>
                                                            <td colSpan={5} className='py-5 text-center font-bold'>
                                                               No hay sesiones habilitadas para esta materia
                                                            </td>
                                                         </tr>

                                                      ):  (
                                                      
                                                      data.data.data.map((item) => {
                                                         const {groupId, groupNumber, startDate, endDate, quota, enclousere, schedule} = item
                                                         const section = {
                                                            groupId,
                                                            groupNumber,
                                                            startDate,
                                                            endDate,
                                                            quota,
                                                            enclousere,
                                                            classroom: schedule[0]?.classroom,
                                                            schedule: schedule.map((s) => ({
                                                                  day: s.day,
                                                                  startTime: s.startTime,
                                                                  endTime: s.endTime
                                                            }))                                                            
                                                         }
                                                         
                                                         return <tr key={item.groupId} className="bg-white border-t border-gray-200 ">
                                                         <td className="px-3 py-1">
                                                         <input onClick={() => addGroupsId(value.subjectId, item.groupId, value.credit, !validateSession(item.groupId), section.schedule, item.quota, item.startDate,item.endDate)} type="checkbox"  checked={validateSession(item.groupId)} id={item.groupId} name={value.subjectCode} className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded cursor-pointer"></input>
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {item.groupNumber}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {item.quota}
                                                         </td>
                                                         
                                                         {/* <td className="px-3 py-1">
                                                         {item.enclousere}
                                                         </td> */}

                                                         <td className="px-3 py-1">
                                                         {formatingDate(item.startDate.substring(0,10))}
                                                         </td>

                                                         <td className="px-3 py-1">
                                                         {formatingDate(item.endDate.substring(0,10))}
                                                         </td>
                                                         
                                                         <td className="px-3 py-1">
                                                         {section.classroom}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {section.schedule.find(day => day.day === "Lunes") ? (
                                                               section.schedule
                                                                     .filter(day => day.day === "Lunes")
                                                                     .map((time, index) => (
                                                                        <span key={index}>
                                                                           {index > 0 && <br />}
                                                                           {convertToStandardTime(time.startTime)} - {convertToStandardTime(time.endTime)}
                                                                        </span>
                                                                     ))
                                                                     .reduce((prev, curr) => [prev, ' ', curr])
                                                            ) : "-"}                                                            
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {section.schedule.find(day => day.day === "Martes") ? (
                                                                  section.schedule
                                                                        .filter(day => day.day === "Martes")
                                                                        .map((time, index) => (
                                                                           <span key={index}>
                                                                              {index > 0 && <br />}
                                                                              {convertToStandardTime(time.startTime)} - {convertToStandardTime(time.endTime)}
                                                                           </span>
                                                                        ))
                                                                        .reduce((prev, curr) => [prev, ' ', curr])
                                                               ) : "-"}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {section.schedule.find(day => day.day === "Miércoles") ? (
                                                               section.schedule
                                                                     .filter(day => day.day === "Miércoles")
                                                                     .map((time, index) => (
                                                                        <span key={index}>
                                                                           {index > 0 && <br />}
                                                                           {convertToStandardTime(time.startTime)} - {convertToStandardTime(time.endTime)}
                                                                        </span>
                                                                     ))
                                                                     .reduce((prev, curr) => [prev, ' ', curr])
                                                            ) : "-"}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {section.schedule.find(day => day.day === "Jueves") ? (
                                                               section.schedule
                                                                     .filter(day => day.day === "Jueves")
                                                                     .map((time, index) => (
                                                                        <span key={index}>
                                                                           {index > 0 && <br />}
                                                                           {convertToStandardTime(time.startTime)} - {convertToStandardTime(time.endTime)}
                                                                        </span>
                                                                     ))
                                                                     .reduce((prev, curr) => [prev, ' ', curr])
                                                            ) : "-"}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {section.schedule.find(day => day.day === "Viernes") ? (
                                                               section.schedule
                                                                     .filter(day => day.day === "Viernes")
                                                                     .map((time, index) => (
                                                                        <span key={index}>
                                                                           {index > 0 && <br />}
                                                                           {convertToStandardTime(time.startTime)} - {convertToStandardTime(time.endTime)}
                                                                        </span>
                                                                     ))
                                                                     .reduce((prev, curr) => [prev, ' ', curr])
                                                            ) : "-"}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                         {section.schedule.find(day => day.day === "Sábado") ? (
                                                            section.schedule
                                                                  .filter(day => day.day === "Sábado")
                                                                  .map((time, index) => (
                                                                     <span key={index}>
                                                                        {index > 0 && <br />}
                                                                        {convertToStandardTime(time.startTime)} - {convertToStandardTime(time.endTime)}
                                                                     </span>
                                                                  ))
                                                                  .reduce((prev, curr) => [prev, ' ', curr])
                                                         ) : "-"}
                                                         </td>
                                                      </tr>
                                                      })
                                                   
                                                      )
                                                   
                                                   }
                                                   
                                                   
                                                   
                                                </tbody>
               
                                             </table>
                                          </div>
                                       </td>
                                    </tr>
                                 }
                           </>
                                                   
                            
                        })
                     
                        )
                     }        
                  </tbody>
               </table>
            </div>
            
               {
                  userPreselection.length ? (
                     <div className="flex justify-end mt-16 mb-6 space-x-6">
                     <button disabled={loadingSave} onClick={() => handleCancel()} className="px-10 py-2 font-semibold text-white uppercase bg-red-600 rounded-full shadow-lg cursor-pointer hover:bg-red-700">Cancelar</button>
                     <button disabled={loadingSave} onClick={() => handleSave()} type="button" value="Guardar" className="px-10 py-2 font-semibold text-white uppercase bg-green-600 rounded-full shadow-lg cursor-pointer hover:bg-green-700">
                     {loadingSave ? (
                <svg
                  className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
              ) : (
                'Guardar'
              )}</button>
                     </div>
                  ):(
                     null
                  )
               }
            
         </div>
            )
      }
      </div>
      )
   }
   </div>
   );
}

export default Preselection;
