
import { useParams,useNavigate } from "react-router-dom"; 
import Grades from '../services/grades.services';
import SweetAlertModal from '../components/SweetAlert';
import {useState, useEffect} from 'react';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const GradesEnter = () => {
   const {id} = useParams();
   const navigate = useNavigate();
   const grades = new Grades();
   const sweetAlertModal = new SweetAlertModal();
   const [calification, setCalification] = useState([]);
   const [errMsg, setErrMsg] = useState('');
   // const [cancelado, setCancelado] = useState(false);
   const [inputValue, setInputValue] = useState({
      qualification: '',
      literal:'',
      qualificationId:'',
   });
   const [inputChanged, setInputChanged] = useState(0)

   useEffect(()=>{
     
      getGrades();
  },[]);

  const getGrades = async () =>{
   await grades.getGradesCalification(id).then((value) =>{
      const data = value.data.data[0];
      setCalification(data);
  });
  }

  const inputChange = async (evento,value) =>{
   setErrMsg('');
   setInputChanged(inputChanged+1);
   if(evento.target.value >=0 && evento.target.value <=100){
      const valor = evento.target.value;

      grades.getGradesCalificationLiteral(valor === '' ? 0 : valor).then((res) =>{
         const literal = res.data.data;
         value.literal = literal.literal;
         value.qualificationId = literal.idCalification;
         setInputValue(
            {
               qualification: value.qualification,
               literal: value.literal,
               qualificationId: value.qualificationId
            },
         );
        
      });
   }
   

  }

  const executeWarning = ({mensaje, title}) => {

   if(inputChanged === 0){
      navigate(-1);
   }else{
      MySwal.fire({
         title: <strong>{title}</strong>,
         html: <b>{mensaje}</b>,
         icon: 'warning',
         showConfirmButton: false,
         showDenyButton: true,
         showCancelButton: true,
         denyButtonText: `No Guardar`,
         cancelButtonText: 'Cancelar',
         }).then(async (result) => {
            if (result.isDenied) {
              
               Swal.fire({title:'Datos no guardados!!', icon:'error',  confirmButtonColor: 'red', confirmButtonText: 'Cerrar' }).then(async (value) => {
                  navigate(-1);
               });
              
            }
         });
   }
        
   
}

const allSafe = () =>{
   const table = document.getElementById("tableGrades");
   for (const row of table.rows) {
      const qualification = row.cells[6].firstChild.value;
      const literal = row.cells[7].lastChild.value;
      if(qualification === '' || literal === ''){
         return false;
      }
   }
}

  const onClickHandle = async() => {
   const isSafe = await allSafe();
   const modifyBy = await localStorage.getItem('userID');
   const datos = [];
   if(isSafe === false){
      setErrMsg('Todas las calificaciones deben ser mayor o igual a cero (0)');
   }else{
      const table = document.getElementById("tableGrades");
      for (const row of table.rows) {
         const enrrollmentId = row.getAttribute('id');
         const qualification = row.cells[6].firstChild.value;
         const literalId = row.cells[7].firstChild.value;
         const data = 
            {
               "enrollmentId": enrrollmentId,
               "qualificationId": literalId,
               modifyBy,
               qualification
            };
         datos.push(data);
         sweetAlertModal.executeConfirm({mensaje:'¿Deseas continuar?', title:'!Guardando cambios!', data: datos,});
      }
   }  
  }


   return (
      <div className="pt-0 sm:pt-10">
         <div className="p-12">
            <h1 className="mb-6 text-xl font-semibold text-green-600">Ingreso de calificaciones</h1>
            <span className="block mb-2 text-sm text-right">Total de alumnos: <b>{calification?.califications?.length}</b></span>
            <div className="relative overflow-x-auto border sm:rounded-lg">
               <table className="w-full text-sm text-left text-gray-500" >
                  <thead className="text-xs text-white uppercase bg-green-600">
                     <tr>
                        <th scope="col" className="px-6 py-3 border-r">
                           Sección
                        </th>
                        <th scope="col" className="px-6 py-3 border-r">
                           Asignatura
                        </th>
                        <th scope="col" className="px-6 py-3 border-r">
                           Matrícula
                        </th>
                        <th scope="col" className="px-6 py-3 border-r">
                           Nombre(s)
                        </th>
                        <th scope="col" className="px-6 py-3 border-r">
                           Primer Apellido
                        </th>
                        <th scope="col" className="px-6 py-3 border-r">
                           Segundo Apellido
                        </th>
                        <th scope="col" className="px-6 py-3 border-r">
                           Calificación
                        </th>
                        <th scope="col" className="px-6 py-3 border-r">
                           Literal
                        </th>
                     </tr>
                  </thead>
                  <tbody id="tableGrades">
                     {
                        calification?.califications?.length > 0 ?
                        calification.califications.map((value)=>(
                           <tr className="bg-white border-t border-gray-200" key={value.enrollmentId} id={value.enrollmentId}>
                              <td className="px-6 py-2">
                                 {calification.sessionInfo.groupName}
                              </td>
                              <td className="px-6 py-2">
                                 {calification.sessionInfo.subject}
                              </td>
                              <td className="px-6 py-2">
                                 {value.enrollment}
                              </td>
                              <td className="px-6 py-2">
                                 {value.studentName}
                              </td>
                              <td className="px-6 py-2">
                                 {value.studentLastName}
                              </td>
                              <td className="px-6 py-2">
                                 {value.studentSecondLastName}
                              </td>
                              <td className="px-6 py-2">
                                 {
                                    value.qualification !== 0 ? 
                                       <input type="number" value={value.qualification} onChange={inputChange} className="w-full px-4 py-1 border rounded-xl" disabled={value.qualification !== 0} min="0" max="100"/>
                                    :  
                                       <input type="number" name={inputValue.qualification} onChange={(e) => inputChange(e,value)} className="w-full px-4 py-1 border rounded-xl" disabled={value.qualification !== 0} maxLength={3}  min="0" max="100"/>
                                 }
                                 
                              </td>
                              <td className="px-6 py-2">
                                 <input type="hidden" name={inputValue.qualificationId} value={value.qualificationId} className="w-16 px-4 py-1 uppercase border rounded-xl" disabled/>
                                 <input type="text" name={inputValue.literal} value={value.literal} className="w-16 px-4 py-1 uppercase border rounded-xl" disabled/>
                              </td>
                           </tr>
                        )) : 
                        <tr>
                           <td colSpan={6} className='text-center'>No hay data que mostrar</td>
                        </tr>
                     }
                     
                     
                  </tbody>
               </table>
            </div>
            <div className="flex justify-end mt-16 mb-6 space-x-6">
               <button className="px-10 py-2 font-semibold text-white uppercase bg-red-600 rounded-full shadow-lg cursor-pointer hover:bg-red-700" onClick={()=> executeWarning({mensaje:'Los datos no han sido guardados, ¿desea cerrar la ventana?', title: '¡Cuidado!'})}>Cancelar</button>
               <button onClick={onClickHandle} className="px-10 py-2 font-semibold text-white uppercase bg-green-600 rounded-full shadow-lg cursor-pointer hover:bg-green-700">Guardar</button>
            </div>
            <div
				className={
					errMsg
						? 'flex justify-end w-full'
						: 'invisible'
				}
				role='alert'
			>
				<div
					className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'
					role='alert'
				>
					<span className='font-medium'>{errMsg}</span>
				</div>
			</div>
         </div>
         
      </div>
   );
}

export default GradesEnter;