import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import RequireAuth from "./components/RequireAuth";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import NoFound from "./pages/NoFound";
import AccountStatus from "./pages/AccountStatus";
import LoginTeacher from "./pages/LoginTeacher";
import RequireAuthTeacher from "./components/RequireAuthTeacher";
import WelcomeTeacher from "./pages/WelcomeTeacher";
import GradesPanel from "./pages/GradesPanel";
import GradesEnter from "./pages/GradesEnter";
import StudentGrades from "./pages/StudentGrades";
import Preselection from "./pages/Preselection";
// import PreselectionPanel from "./pages/PreselectionPanel";
import PreselectionView from "./pages/PreselectionView";

function App() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="login-profesores" element={<LoginTeacher />} />
      <Route path="/*" element={<NoFound />} />

      <Route path="/" element={<Layout />}>
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Welcome />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/estado-cuenta" element={<AccountStatus />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/seleccion" element={<Preselection />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/selection-panel" element={<PreselectionView />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/calificaciones" element={<StudentGrades />} />
        </Route>
      </Route>
      <Route path="/" element={<Layout />}>
        <Route element={<RequireAuthTeacher />}>
          <Route path="/" element={<WelcomeTeacher />} />
        </Route>
        <Route element={<RequireAuthTeacher />}>
          <Route path="/calificaciones-profesor" element={<GradesPanel />} />
        </Route>
        <Route element={<RequireAuthTeacher />}>
          <Route path="/ingreso-calificaciones/:id" element={<GradesEnter />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
