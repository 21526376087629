import { useIdleTimer } from 'react-idle-timer'
import {  useNavigate, useLocation, Outlet } from 'react-router-dom';
import useAuth from "../hooks/useAuth";

const IdleTimerContainerTeacher = ()=> {
  const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const onIdle = () => {
      setAuth({});
      localStorage.removeItem("token");
      localStorage.removeItem("userID");
      localStorage.removeItem("userName");
      localStorage.removeItem("setType");
      navigate(from, { replace: true });
        
    }    
      // eslint-disable-next-line no-unused-vars
      const idleTimer = useIdleTimer({ onIdle, timeout: 20 * 60 * 1000, })
    
      return (
        <Outlet/>
      )
}

export default IdleTimerContainerTeacher