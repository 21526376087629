import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import IdleTimerContainerTeacher from "./IdleTimerContainerTeacher";

const RequireAuthTeacher = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return auth?.user || localStorage.getItem("token") ? (
    <IdleTimerContainerTeacher/>
  ) : (
    <Navigate to="/login-profesores" state={{ from: location }} replace />
  );
};

export default RequireAuthTeacher;
