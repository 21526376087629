import { useState } from 'react';
import StudentDashboard from '../components/StudentDashboard';

const Welcome = () => {
	const [userName] = useState(localStorage.getItem('userName'));
   const [userType] = useState(localStorage.getItem('setType'));
   const [userGender] = useState(localStorage.getItem('userGender'));

   let letterUserGender = '';

   if (userGender === 'H'){
       letterUserGender = 'o'
   }else if(userGender === "M"){
       letterUserGender = 'a'
   }

   if(userType==='0'){
      return ( <StudentDashboard/> )
   }else if(userType==='1'){
      return(
         <div className='flex flex-wrap items-center justify-center w-full pt-24 md:pt-48'>
            <h1 className='w-full mb-4 text-6xl font-semibold text-center text-green-600'>
               ¡Bienvenid{letterUserGender}!
            </h1>
            <span className='block text-xl font-semibold'>{userName}</span>
         </div>
      )
   };

};

export default Welcome;
