import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
// import ModalChangePassword from './ModalChangePassword';
import ChangePasswordModal from '../components/ModalPasswordChange';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MenuComponent = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [showModal,setShowModal] = useState(false);

  const logOut = async (e) => {
    e.preventDefault();
    setAuth({});
    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    localStorage.removeItem("userName");
    localStorage.removeItem("setType");
    localStorage.removeItem("userIdLoggin");
    localStorage.removeItem("userGender");
    navigate(from, { replace: true });
  };

  const goToTeacherLogin = async (e) => {
    e.preventDefault();
    setAuth({});
    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    localStorage.removeItem("userName");
    localStorage.removeItem("setType");
    localStorage.removeItem("userIdLoggin");
    localStorage.removeItem("userGender");
    navigate('login-profesores', { replace: true });
  };
  
  const hanndleShowButton = () =>{
    setShowModal(true);
  }


  return (
    <div className="flex justify-center align-middle align-items-center">
      <div className="py-2 mr-2 text-sm">
        {localStorage.getItem('userIdLoggin')}
      </div>
      <Menu as="div" className="relative z-20 inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium rounded-md text-black-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          Mi cuenta
          <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-56 py-2 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
          <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    "text-gray-500",
                    "block w-full px-4 py-2 text-left text-sm"
                  )}
                >
                  {localStorage.getItem('userName')}
                </button>
              )}
            </Menu.Item>
          </div>
          <hr />
          <div className="py-1">
            
            <Menu.Item>
              {({ active }) => (
                <button
                onClick={hanndleShowButton.bind(this)}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block w-full px-4 py-2 text-left text-sm"
                  )}
                  
                >
                 Cambio de contraseña
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={goToTeacherLogin}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block w-full px-4 py-2 text-left text-sm"
                  )}
                >
                  Acceso profesores
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={logOut}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block w-full px-4 py-2 text-left text-sm"
                  )}
                >
                  Cerrar sesión
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
      <div>
        {showModal ? (
          <ChangePasswordModal setShowModal={setShowModal} userID={localStorage.getItem('userID')}/>
        ) : null}
      </div>
    </div>
    
  );
};

export default MenuComponent;
