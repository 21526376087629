import { useState, useEffect } from 'react';
import getStatus from '../services/accountStatus.service';


const AccountStatus = () => {
	const [userData, setUserData] = useState({
		nombre: '',
		matricula: '',
	});

	const [tableData, setTableData] = useState([]);

	const getData = () => {
		getStatus().then(value => {
			
			setUserData({
				nombre: `${value?.data?.data?.firstName} ${value?.data?.data?.lastName}`,
				matricula: value?.data?.data?.enrollment,
			});
			const dataUSer = [];
			setTableData(value?.data?.data?.accounts);
			console.log(dataUSer);
		});
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className='pt-0 sm:pt-5'>
			<div className='p-12 md:p-12'>
				<h1 className='mb-0 text-xl font-semibold text-green-600'>
					Estado de cuenta
				</h1>
				<div className='grid grid-cols-1 gap-4 py-6 mb-6 bg-white lg:grid-cols-3 md:sticky top-20 md:z-10'>
					<div>
						<label className='block mb-2 ml-5 text-sm font-medium text-gray-900'>Nombre</label>
						<input
							type='text'
							placeholder='Nombre'
							value={userData.nombre}
							className='w-full px-4 py-2 border-2 rounded-2xl bg-gray-50'
							readOnly
						/>
					</div>
					<div>
						<label className='block mb-2 ml-5 text-sm font-medium text-gray-900'>Matricula</label>
						<input
							type='text'
							placeholder='Matrícula'
							value={userData.matricula}
							className='w-full px-4 py-2 border-2 rounded-2xl bg-gray-50'
							readOnly
						/>
					</div>
				</div>
				
				<span className='block mb-3 mr-2 text-sm text-right'>
					Cantidad de registros: <b>{tableData.length}</b>
				</span>
				{ <div className='relative overflow-x-auto border sm:rounded-lg'>
					<table className='w-full text-sm text-left text-gray-500'>
						<thead className='text-xs text-white uppercase bg-green-600'>
							<tr>
								<th scope='col' className='px-6 py-3'>
									ID de Cuenta
								</th>
								{/* <th scope='col' className='px-6 py-3'>
									Moneda
								</th> */}
								<th scope='col' className='px-6 py-3'>
									Saldo Vencido
								</th>
								<th scope='col' className='px-6 py-3'>
									Saldo no vencido
								</th>
								<th scope='col' className='px-6 py-3'>
									Saldo a favor
								</th>
							</tr>
						</thead>
						<tbody>
							{tableData.length < 0 ?  (
								<tr>
									<td colSpan={5} className='py-5 text-center'>
										No hay datos para mostrar
									</td>
								</tr>
							):  (
								tableData.map(data => (
									<tr
										key={data.accountId}
										className='bg-white border-t border-gray-200'
									>
										<th
											scope='row'
											className='px-6 py-2 font-medium text-gray-900 whitespace-nowrap'
										>
											{data.accountId}
										</th>
										{/* <td className='px-6 py-2'>{data.currency}</td> */}
										<td className='px-6 py-2 text-right'>
											{data.pastDueBalance}
										</td>
										<td className='px-6 py-2 text-right'>
											{data.balanceNotDue}
										</td>
										<td className='px-6 py-2 text-right'>
											{data.positiveBalance}
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>}
			</div>
		</div>
	);
};

export default AccountStatus;
