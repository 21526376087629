import logo from "../img/LOGO-UAFAM.png";
import { Navbar, Dropdown } from "flowbite-react";
import MenuComponent from "./Menu";



function NavbarUI() {
  
  return (
      <Navbar
        className="px-2 sm:px-4 py-2.5 sm:py-6 sm:fixed top-0 left-0 nav-bar-height w-full z-10"
        fluid={true}
      >
        <div className="flex flex-wrap items-center justify-between w-full mx-auto md:px-4">
          <img src={logo} className="h-6 mr-3 sm:h-9" alt="UAFAM Logo" />
          <span className="justify-center hidden ml-40 text-lg font-semibold text-green-600 whitespace-nowrap lg:block">
            Universidad Agroforestal Fernando Arturo de Meriño
          </span>
          
          
          <MenuComponent />
          <div
            className="items-center justify-between hidden w-full md:hidden md:w-auto md:order-1"
            id="mobile-menu-2"
          >
            <Dropdown className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-red ">
              <li>
                <a
                  href="!#"
                  className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                >
                  Elemento 1
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                >
                  Elemento 2
                </a>
              </li>
              <li>
                <a
                  href="!#"
                  className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                >
                  Elemento 3
                </a>
              </li>
            </Dropdown>
          </div>
        </div>
      </Navbar>
  );
}

export default NavbarUI;
