import InputMask from 'react-input-mask';

const InputWithMask = (props)=> {
  return (
    <InputMask 
      mask='9999-9999' 
      value={props.value} 
      onChange={props.onChange}>
        {(inputProps) => 
        <input 
        type="text" 
        required
		placeholder='Usuario'
        className="w-full px-4 py-2 md:w-80 rounded-2xl bg-gray-50" 
        name='Usuario'
        autoComplete='off'
        id='username' {...inputProps} />}
    </InputMask>
  );
}

export default InputWithMask;