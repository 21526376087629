import axios from "axios";
import { BASE_URL } from "../config/baseUrl";

const GET_PRESELECTION_URL = "/Preselection/getPreselection";
const GET_REGLAPENSUM_URL = "/Preselection/getReglaPensum";
const GET_SELECTEDPRESELECTION_URL = "/Preselection/getPrematricula";
const GET_ACTIVE_SESSIONS_URL = "/Preselection/getActiveSession";
const DELETE_PRESELECTION_URL = "/Preselection/deletePreselection";
const GET_SELECTED_SUBJECT_URL = "/Preselection/Preselection/getSelectedSubject";
const POST_SELECTION_URL = "/Preselection/savePreselection";
const GET_VALIDATE_REQUIREMENTS_URL = "/Preselection/validateRequirements";
const GET_VALIDATE_PRESELECTION_DATE_URL = "/Preselection/getFechasPrematriculas";
const PATCH_SELECTION_URL = "/Preselection/updatePreselection";
const POST_NEWSELECTION_URL = "/Preselection/createPreselection";

class Preselection {
    getHeader = () => {
      const token = localStorage.getItem("token");
      const header = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      };
      return header;
    };

    validatePreselectionDate = async () => {
      return axios({
        method: "get",
        headers: this.getHeader(),
        url: `${BASE_URL}${GET_VALIDATE_PRESELECTION_DATE_URL}`,
      });
    };

    validateRequeriments = async (personId, requirementsId) => {
      return axios({
        method: "get",
        headers: this.getHeader(),
        url: `${BASE_URL}${GET_VALIDATE_REQUIREMENTS_URL}?personId=${personId}&requirementsId=${requirementsId}`,
      });
    };

    getPreselection = async (personId, periodId, year) => {
      return axios({
        method: "get",
        headers: this.getHeader(),
        url: `${BASE_URL}${GET_PRESELECTION_URL}?personId=${personId}&periodId=${periodId}&year=${year}`,
      });
    };

    getReglaPensum = async (personId) => {
      return axios({
        method: "get",
        headers: this.getHeader(),
        url: `${BASE_URL}${GET_REGLAPENSUM_URL}?personId=${personId}`,
      });
    };

    getPreselectionSelected = async (personId, periodId, year) => {
      return axios({
        method: "get",
        headers: this.getHeader(),
        url: `${BASE_URL}${GET_SELECTEDPRESELECTION_URL}?studentId=${personId}&idPeriod=${periodId}&year=${year}`,
      });
    };


    getActiveSessions = async (subjectId, periodId, year) => {
      return axios({
        method: "get",
        headers: this.getHeader(),
        url: `${BASE_URL}${GET_ACTIVE_SESSIONS_URL}?subjectId=${subjectId}&periodId=${periodId}&year=${year}`,
      });
    };

    getSelectedSubject = async (studentId, periodId, year) => {
      return axios({
        method: "get",
        headers: this.getHeader(),
        url: `${BASE_URL}${GET_SELECTED_SUBJECT_URL}?studentId=${studentId}&periodId=${periodId}&year=${year}`,
      });
    };

    postSaveSelection = async (personId, groupList) => {
      return axios({
        method: "post",
        headers: this.getHeader(),
        url: `${BASE_URL}${POST_SELECTION_URL}?personId=${personId}`,
        data: groupList
      });
    }

    patchUpdateSelection = async (updatePreselectionJson) => {
      return axios({
        method: "patch",
        headers: this.getHeader(),
        url: `${BASE_URL}${PATCH_SELECTION_URL}?`,
        data: updatePreselectionJson
      });
    }

    postSaveNewSelection= async (personId, groupList) => {
      return axios({
        method: "post",
        headers: this.getHeader(),
        url: `${BASE_URL}${POST_NEWSELECTION_URL}?personId=${personId}`,
        data: groupList
      });
    }

    deletePreselection = async (preselectionId) => {
      return axios({
        method: "delete",
        headers: this.getHeader(),
        url: `${BASE_URL}${DELETE_PRESELECTION_URL}`,
        data:preselectionId,
      });
    };
}

export default Preselection;
