import { useState, useEffect } from 'react';
import getDashboard from '../services/dashboard.service'


const StudentDashboard = () => {
  
    const [userName] = useState(localStorage.getItem('userName'));
    const [userGender] = useState(localStorage.getItem('userGender'));

    let letterUserGender = '';

    if (userGender === 'H'){
        letterUserGender = 'o'
    }else if(userGender === "M"){
        letterUserGender = 'a'
    }


    const [dashboardData, setdashboardData] = useState({
        cumulativeIndex: '',
        quarterIndex: '',
        studiedSubjects: ''
        });
    
    useEffect(()=>{
        getDashboard().then((value) =>{
            setdashboardData({
                cumulativeIndex: parseFloat(value?.data?.data?.cumulativeIndex).toFixed(2),
                quarterIndex: parseFloat(value?.data?.data?.quarterIndex).toFixed(2),
                studiedSubjects: value?.data?.data?.studiedSubjects
                });
          });
        },[]);  
  
  
  
    return (
        <>
            <div className='flex flex-wrap items-center justify-center w-full pt-24 md:pt-48'>
                <h1 className='w-full mb-4 text-6xl font-semibold text-center text-green-600'>
                    ¡Bienvenid{letterUserGender}!
                </h1>
                <span className='block text-xl font-semibold'>{userName}</span>
            </div>
            <div className="grid items-center justify-center grid-cols-2 pt-12 mx-auto lg:grid-cols-3 gap-9 md:max-w-3xl">
                <div className="p-4 text-white bg-indigo-500 shadow-lg">
                    <span className="block text-3xl font-bold">{dashboardData.cumulativeIndex}</span>
                    <span className="text-lg">Índice Acumulado</span>
                </div>
                <div className="p-4 text-white shadow-lg bg-emerald-500">
                    <span className="block text-3xl font-bold">{dashboardData.quarterIndex}</span>
                    <span className="text-lg">Índice de Cuatrimestre</span>
                </div>
                <div className="p-4 text-white shadow-lg bg-amber-500">
                    <span className="block text-3xl font-bold">{dashboardData.studiedSubjects}</span>
                    <span className="text-lg">Materias cursadas</span>
                </div>
            </div>
        </>
    );
};

export default StudentDashboard;
