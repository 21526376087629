import logo from '../img/LOGO-UAFAM.png';
import { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import getUSerData from '../services/getUser.service';
import loginService from '../services/login.service';
import InputWithMask from '../components/InputWithMask';
import ModalRecoveryPassword from '../components/ModalRecoveryPassword';
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import ChangePasswordModal from '../components/ModalPasswordChange';
 

function Login() {
	const { setAuth } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || '/';
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState('');
	const [userId, setUserId] = useState('');
	// const [pwd, setPwd] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [passValue, setPassValue] = useState({
		password: "",
		showPassword: false,
	  });


	const [showModal,setShowModal] = useState(false);

	useEffect(() => {
		setErrMsg('');
	}, [user, passValue.password]);


	const handlePasswordChange = (prop) => (event) => {
		setPassValue({ ...passValue, [prop]: event.target.value });
	  };

	  const handleClickShowPassword = () => {
		setPassValue({ ...passValue, showPassword: !passValue.showPassword });
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		try {
			loginService(user,passValue.password).then(value => {
				if(value?.data?.token === '' || value?.data?.token === undefined || value?.data?.token === null ){
					setErrMsg('Usuario y/o contraseña incorecta');
					setLoading(false);
				}else{
					if (value?.data?.statusCode === 400) {
						setErrMsg(value?.data?.data);
						setLoading(false);
					} else {
						if (value?.data?.statusCode === 400) {
							setErrMsg(value?.data?.data);
							setLoading(false);
						} else {
							const accessToken = value?.data?.token;
							const userID = value?.data?.userId;
							setUserId(userID);
							const pwd =  passValue.password;
							if(value?.data?.hasChangePass === true)  {
								setLoading(false);
								setShowModal(true);
							}
							else {
								setAuth({ user, pwd, accessToken });
								localStorage.setItem('token', accessToken);
								localStorage.setItem('userID',userID);
								localStorage.setItem('setType',0);
								localStorage.setItem('userIdLoggin',user);
								getUSerData(userID, accessToken).then(value => {
									const userName = `${value?.data?.data?.firstName} ${value?.data?.data?.lastName}`;
									const userGender = value?.data?.data?.gender;
									localStorage.setItem('userName', userName);
									localStorage.setItem('userGender',userGender);
									setLoading(false);
									setUser('');
									setPassValue('');
									navigate(from, { replace: true });
								});
							}
							}
					}
				}
				
			});
	} catch (err) {
        if (!err?.response) {
            setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Login Failed');
        }
		setLoading(false);
    }

		
	};

	// const showPassword = () =>{

	// }

	return (
		<div>
		<form onSubmit={handleSubmit}>
			<div className='flex items-center justify-center h-screen bg-gradient-to-r from-white to-gray-200'>
				<div className='max-w-xl px-10 py-6 bg-white shadow-md rounded-3xl md:py-16 md:px-20'>
					<img
						src={logo}
						alt='logo de UAFAM'
						className='w-48 h-auto mx-auto mb-4'
					/>
					<h1 className='text-lg font-bold text-center text-green-600 uppercase'>
						Iniciar Sesión
					</h1>
					<div className='mt-6 space-y-4'>
						<div className='w-full'>
							
							<InputWithMask
                                onChange={e => setUser(e.target.value)}>
                            </InputWithMask>
						</div>
						
						<div className="relative block password_2">
							<div className="eye_div">
								<input
								className="block w-full px-4 py-2 input rounded-2xl bg-gray-50 "
								type={passValue.showPassword ? 'text' : 'password'}
								onChange={handlePasswordChange("password")}
								required
								placeholder='********'
								value={passValue.password}
								/>
								<div
									className="absolute icon_button right-4 top-2"
									onClick={handleClickShowPassword}
									>
									{passValue.showPassword ? (
										<EyeIcon className="h-6 font-extralight" />
									  
									) : (
										<EyeSlashIcon className="h-6 font-extralight" />

									)}
								</div>
							</div>
						</div>
					</div>
					<button
						className='w-full py-3 mt-5 font-semibold tracking-tight text-white uppercase bg-green-600 text-align-center hover:bg-green-700 rounded-2xl'
						disabled={loading}
					>
						{loading ? (
							<svg
								className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300'
								viewBox='0 0 100 101'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
									fill='currentColor'
								/>
								<path
									d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
									fill='currentFill'
								/>
							</svg>
						) : (
							' Ingresa'
						)}
					</button>
					<ModalRecoveryPassword />
				</div>
			</div>
			<div
				className={
					errMsg
						? 'absolute bottom-0 left-0 flex justify-center w-full'
						: 'invisible'
				}
				role='alert'
			>
				<div
					className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'
					role='alert'
				>
					<span className='font-medium'>{errMsg}</span>
				</div>
			</div>
		</form>
		{
			showModal ? (
				<ChangePasswordModal setShowModal={setShowModal} userID={userId}/>
			):(
				null
			)
		}
		</div>
	);
}

export default Login;
