class RealMockApi{

    titleTable(){
        const data = ["Código asignatura","Asignatura","Crédito","Requisitos","Acción"];
        return data;
    }

    titlePreselectionTable(){
      const data = ["Código asignatura","Asignatura","Sesión","Fecha inicio","Fecha fin","Aula","Horario"];
      return data;
  }

    subjectRequest(){
        const data = [
            {
              subjectId: 125,
              subjectCode: "EDR-100",
              subjectName: "ANDRAGOGIA I",
              credit: 2,
              requirements: [],
              idPeriod: 2,
              idPeriodPensum: 1,
              year: 2023
            },
            {
              subjectId: 206,
              subjectCode: "HUM-172",
              subjectName: "CIENCIA AMBIENTAL II",
              credit: 2,
              requirements: [
                "HUM-171"
              ],
              idPeriod: 2,
              idPeriodPensum: 2,
              year: 2023
            },
            {
              subjectId: 110,
              subjectCode: "ECP-100",
              subjectName: "INTRODUCCION A LA EDUCACION",
              credit: 3,
              requirements: [],
              idPeriod: 2,
              idPeriodPensum: 6,
              year: 2023
            },
            {
              subjectId: 111,
              subjectCode: "ECP-101",
              subjectName: "INTRODUCCION A LA FILOSOFIA",
              credit: 3,
              requirements: [],
              idPeriod: 2,
              idPeriodPensum: 6,
              year: 2023
            },
            {
              subjectId: 112,
              subjectCode: "ECP-102",
              subjectName: "INTRODUCCION A LA SOCIOLOGIA",
              credit: 3,
              requirements: [],
              idPeriod: 2,
              idPeriodPensum: 6,
              year: 2023
            },
            {
              subjectId: 113,
              subjectCode: "ECP-103",
              subjectName: "INTRODUCCION A LA PSICOLOGIA",
              credit: 3,
              requirements: [],
              idPeriod: 2,
              idPeriodPensum: 6,
              year: 2023
            },
            {
              subjectId: 8,
              subjectCode: "ADM-107",
              subjectName: "TRABAJO DE GRADO",
              credit: 6,
              requirements: [],
              idPeriod: 2,
              idPeriodPensum: 11,
              year: 2023
            }
        ];
        return data;
    }



    activeSessionRequest(){
        const data = [
            {
                "groupId": 106610,
                "quota": "30",
                "enclosure": "SEDE UAFAM",
                "schedule": [
                  {
                    "classroom": "A. No definida 6",
                    "day": "Sábado",
                    "startTime": "1300",
                    "endTime": "1700"
                  }
                ]
              },
              {
                "groupId": 106611,
                "quota": "30",
                "enclosure": "SEDE UAFAM",
                "schedule": [
                  {
                    "classroom": "A. No definida 3",
                    "day": "Viernes",
                    "startTime": "1800",
                    "endTime": "2200"
                  }
                ]
              }
        ];
        return data;
    }





}

export default RealMockApi;
