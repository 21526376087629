import {useState, useEffect} from 'react';
import { FiAlertTriangle, FiEdit } from "react-icons/fi";
import Grades from '../services/grades.services';
import { Link } from "react-router-dom";

const GradesPanel = () => {
    const grades = new Grades();
    const [selectLevels, setSelectLevels ] = useState([]);
    const [selectYears, setSelectYears ] = useState([]);
    const [selectPeriods, setSelectPeriods ] = useState([]);
    const [levels, setLevels] = useState('');
    const [year, setYear] = useState('');
    const [period, setPeriod] = useState('');
    const [session, setSession] = useState([]);

    useEffect(()=>{
        grades.getLevels().then((value) =>{
            const data = value.data.data
            setSelectLevels(data);
        });
    },[]);

    const handleLevelChange = async (e) =>{
        setSelectYears([]);
        setSelectPeriods([]);
        setLevels(e.target.value);
        grades.getYears(e.target.value).then((value) =>{
            const data = value.data.data.sort();
            setSelectYears(data);
        });
    }

    const handleYearChange = async (e) =>{
        setSelectPeriods([]);
        grades.getPeriods(levels, e.target.value).then((value) =>{
            setYear(e.target.value)
            const data = value.data.data
            setSelectPeriods(data);
        });
    }

    const handlePeriodChange = async (e) =>{
        setPeriod(e.target.value);
        setSession([]);
    }

    const findDatSection = async (e) => {
        e.preventDefault();
        await grades.getTeacherSession(period, year).then((value) =>{
            const data = value.data.data
            data ? setSession(data) : console.log("No hay datos que mostrar")
            
        });
    }


   return (
      <div className="pt-0 sm:pt-5 ">
         <div className="p-12">
            <h1 className="mb-0 text-xl font-semibold text-green-600">Calificaciones</h1>
            <div className="grid grid-cols-1 gap-4 pt-6 mb-6 bg-white lg:grid-cols-3">
               <div className="">
                  <label className="block mb-2 ml-5 text-sm font-medium text-gray-900">Nivel</label>
                     <select onChange={handleLevelChange}className="w-full px-4 py-2 border rounded-2xl bg-gray-50">
                     <option hidden>Elige el Nivel</option>
                     {
                        selectLevels.map((value)=>(
                            <option key={value} value={value}>{value}</option>
                        ))
                     }
                  </select>
               </div>
               <div className="">
                  <label  className="block mb-2 ml-5 text-sm font-medium text-gray-900">Año</label>
                     <select id="year" onChange={handleYearChange} className="w-full px-4 py-2 border rounded-2xl bg-gray-50">
                     <option hidden>Elige el Año</option>
                     {
                        selectYears.map((value)=>(
                            <option key={value} value={value}>{value}</option>
                        ))
                     }
                  </select>
               </div>
               <div className="">
                  <label  className="block mb-2 ml-5 text-sm font-medium text-gray-900">Periodo</label>
                     <select id="period" onChange={handlePeriodChange} className="w-full px-4 py-2 border rounded-2xl bg-gray-50">
                     <option hidden>Elige el Periodo</option>
                     {
                        selectPeriods.map((value)=>(
                            <option key={value.id} value={value.id}>{value.name}</option>
                        ))
                     }
                  </select>
               </div>
            </div>
            <div className="flex justify-end mb-6">
               <input type="submit" onClick={findDatSection} value="Buscar" className="px-10 py-2 font-semibold text-white uppercase bg-green-600 rounded-full shadow-lg cursor-pointer hover:bg-green-700" />
            </div>
            <div id="advice" className="items-center hidden mb-4 space-x-2">
               <FiAlertTriangle className="text-4xl text-yellow-500" /><span className="font-semibold">El ingreso de calificaciones para el periodo Mayo - Agosto 2022 ha concluido.</span>
            </div>
                <span className="block mb-2 text-sm text-right">Total de sesiones: <b>{session.length}</b></span>
                     
                   
                        
                    <div className="relative overflow-x-auto border sm:rounded-lg">
                       <table className="w-full text-sm text-left text-gray-500">
                           <thead className="text-xs text-white uppercase bg-green-600">
                               <tr>
                                   <th scope="col" className="px-6 py-3 border-r">
                                       Sección
                                   </th>
                                   <th scope="col" className="px-6 py-3 border-r">
                                       Código asignatura
                                   </th>
                                   <th scope="col" className="px-6 py-3 border-r">
                                       Asignatura
                                   </th>
                                   <th scope="col" className="px-6 py-3 border-r">
                                       Estado
                                   </th>
                                   <th scope="col" className="px-6 py-3 border-r">
                                       Notas oficiales
                                   </th>
                                   <th scope="col" className="px-6 py-3 border">
                                       Acciones
                                   </th>
                               </tr>
                           </thead>
                           <tbody>

                               {
                                session.length ?
                                session.map((value)=>(
                                    <tr className="bg-white border-t border-gray-200" key={value.groupId}>
                                        <td className="px-6 py-2">
                                            {value.session}
                                        </td>
                                        <td className="px-6 py-2">
                                        {  value.session.split('-')[0] + '-' + value.session.split('-')[1]}
                                        </td>
                                        <td className="px-6 py-2">
                                            {value.subject}
                                        </td>
                                        <td className="px-6 py-2">
                                            {value.status}
                                        </td>
                                        <td className="px-6 py-2 text-center">
                                            <input type="checkbox" className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded" disabled checked={value.officialNotes}></input>
                                        </td>
                                        <td className="px-6 py-2">
                                            <Link to={`/ingreso-calificaciones/${value.groupId}`}>
                                                <FiEdit className="text-xl text-gray-700 cursor-pointer hover:text-black" />
                                            </Link>
                                            
                                        </td>
                                    </tr>
                                )) : 
                                <tr>
                                    <td colSpan={6} className='text-center'>No hay data que mostrar</td>
                                </tr>
                               }
                               
                           </tbody>
                       </table>
                   </div>
                     
         </div>
      </div>
   );
}

export default GradesPanel;