import axios from "axios";
import { BASE_URL } from "../config/baseUrl";
const ACCOUNTSTATE_URL = "/Student/getStudentAccountState";

const getStatus = async () => {
  const userID = await localStorage.getItem("userID");
  return await axios({
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    url: `${BASE_URL}${ACCOUNTSTATE_URL}?id=${userID.toString("base64")}`,
  });
};

export default getStatus;
