import axios from "axios";
import { BASE_URL } from "../config/baseUrl";
const DASHBOARD_URL = "/Student/getDashboard";

const getDashboard = async () => {
  const userID = await localStorage.getItem("userID");
  return await axios({
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    url: `${BASE_URL}${DASHBOARD_URL}?personId=${userID.toString("base64")}`,
  });
};

export default getDashboard;
