import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
// 
// import SideBar from "./SideBar";
import Aside from "./Aside";


const Layout = () => {
  return (
    <>
      <Navbar />
     
      <div className="flex w-full">
        <div className="side-bar-height">
          <Aside/>
        </div>
        <div className="outlet-part">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
