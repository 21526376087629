import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Grades from '../services/grades.services';
import Preselection from '../services/preselection.service';


const MySwal = withReactContent(Swal);
const grades = new Grades();
const preselection = new Preselection();

class SweetAlertModal {

   executeConfirm = ({mensaje, title, data}) => {
      MySwal.fire({
         title: <strong>{title}</strong>,
         html: <b>{mensaje}</b>,
         icon: 'warning',
         showDenyButton: false,
         showCancelButton: true,
         confirmButtonColor: '#16a34a',
         confirmButtonText: 'Guardar',
         cancelButtonText: 'Cancelar',
         }).then(async (result) => {
            if (result.isConfirmed) {
               grades.putGradesCalificationLiteral(data).then(()=>Swal.fire({
                  title:'¡Cambios Guardados!', 
                  icon:'success',
                  confirmButtonColor: '#16a34a',
                  confirmButtonText: 'Cerrar'
               }));
               
            } else if (result.isDenied) {
               Swal.fire({
               title: 'Los cambios no se guardaron', 
               icon: 'info', 
               confirmButtonColor: '#16a34a',
               confirmButtonText: 'Cerrar',
            })
            }
         })
   }

   executeConfirmSelection  = async({mensaje, title, currentUserId,groupIds}) => {
      let resultMessage = {};
      await MySwal.fire({
         title: <strong>{title}</strong>,
         html: <b>{mensaje}</b>,
         icon: 'warning',
         showDenyButton: false,
         showCancelButton: true,
         confirmButtonColor: '#16a34a',
         confirmButtonText: 'Guardar',
         cancelButtonText: 'Cancelar',
         }).then(async (result) => {
            if (result.isConfirmed) {
               await preselection.postSaveSelection(currentUserId, groupIds).then(value => {
                  if(value?.data === 'Ya tiene preseleccion en este periodo'){
                     Swal.fire({
                        title:'Cambios no guardados', 
                        text: 'Ya posees una preselección en este período. Puedes consultarla y/o modificarla en la pantalla "Gestionar selección"',
                        icon:'error',
                        confirmButtonColor: '#16a34a',
                        confirmButtonText: 'Cerrar'
                     })
                  }else if(value?.data === 'No posee cupo disponible'){
                     Swal.fire({
                        title:'Cambios no guardados', 
                        text: 'Una o más de las sesiones seleccionadas ya no poseen cupos disponibles.',
                        icon:'error',
                        confirmButtonColor: '#16a34a',
                        confirmButtonText: 'Cerrar'
                     })
                  }else{
                     Swal.fire({
                        title:'¡Cambios Guardados!', 
                        icon:'success',
                        confirmButtonColor: '#16a34a',
                        confirmButtonText: 'Cerrar'
                     })
                  }
                  resultMessage=value;
               }         
               );
   
               
            } else if (result.isDenied) {
               Swal.fire({
               title: 'Los cambios no se guardaron', 
               icon: 'info', 
               confirmButtonColor: '#16a34a',
               confirmButtonText: 'Cerrar',
            })
            
            }
            
         })
         return resultMessage;
   }

   executeConfirmUpdateSelection  = async({mensaje, title,currentUserId,updatePreselectionJson,groupIds}) => {
      let resultMessage = {};
      await MySwal.fire({
         title: <strong>{title}</strong>,
         html: <b>{mensaje}</b>,
         icon: 'warning',
         showDenyButton: false,
         showCancelButton: true,
         confirmButtonColor: '#16a34a',
         confirmButtonText: 'Guardar',
         cancelButtonText: 'Cancelar',
         }).then(async (result) => {
            if (result.isConfirmed) {
               if(updatePreselectionJson?.updatePreselection.length){
                  await preselection.patchUpdateSelection(updatePreselectionJson).then(value => {
                     const response=value?.data?.data;
                     if(response?.length){
                        Swal.fire({
                           title:'Cambios no guardados', 
                           text: 'Una o más de las sesiones seleccionadas ya no poseen cupos disponibles: '
                           +[response?.array.forEach(element => {
                              return element.subjectCode
                           })],
                           icon:'error',
                           confirmButtonColor: '#16a34a',
                           confirmButtonText: 'Cerrar'
                        })
                     }else{
                        Swal.fire({
                           title:'¡Cambios Guardados!', 
                           icon:'success',
                           confirmButtonColor: '#16a34a',
                           confirmButtonText: 'Cerrar'
                        })
                     }
                     resultMessage=value;
                  }         
                  );
               }
               if(groupIds?.length){
                  await preselection.postSaveNewSelection(currentUserId, groupIds).then(value => {
                     if(value?.data === 'Ya tiene preseleccion en este periodo'){
                        Swal.fire({
                           title:'Cambios no guardados', 
                           text: 'Ya posees una preselección en este período. Puedes consultarla y/o modificarla en la pantalla "Gestionar selección"',
                           icon:'error',
                           confirmButtonColor: '#16a34a',
                           confirmButtonText: 'Cerrar'
                        })
                     }else if(value?.data === 'No posee cupo disponible'){
                        Swal.fire({
                           title:'Cambios no guardados', 
                           text: 'Una o más de las sesiones seleccionadas ya no poseen cupos disponibles.',
                           icon:'error',
                           confirmButtonColor: '#16a34a',
                           confirmButtonText: 'Cerrar'
                        })
                     }else{
                        Swal.fire({
                           title:'¡Cambios Guardados!', 
                           icon:'success',
                           confirmButtonColor: '#16a34a',
                           confirmButtonText: 'Cerrar'
                        })
                     }
                     resultMessage=value;
                  }         
                  );
               }
               
            } else if (result.isDenied) {
               Swal.fire({
               title: 'Los cambios no se guardaron', 
               icon: 'info', 
               confirmButtonColor: '#16a34a',
               confirmButtonText: 'Cerrar',
            })
            
            }
            
         })
         return resultMessage;
   }

   deletePreselectionConfirm = async({mensaje, title, data}) => {
      let resultMessage = {};
      await MySwal.fire({
         title: <strong>{title}</strong>,
         html: <b>{mensaje}</b>,
         icon: 'warning',
         showDenyButton: false,
         showCancelButton: true,
         confirmButtonColor: '#16a34a',
         confirmButtonText: 'Guardar',
         cancelButtonText: 'Cancelar',
         }).then(async (result) => {
            console.log(result);
            if (result.isConfirmed) {
               try {
                  await preselection.deletePreselection(data);
                  Swal.fire({
                    title: '¡Materia eliminada!',
                    icon: 'success',
                    confirmButtonColor: '#16a34a',
                    confirmButtonText: 'Cerrar'
                  });
                  resultMessage = true;
                } catch (error) {
                  console.log('Error:', error);
                  resultMessage = true;
                }
            } else if (result.isDenied) {
               Swal.fire({
               title: 'Los cambios no se guardaron', 
               icon: 'info', 
               confirmButtonColor: '#16a34a',
               confirmButtonText: 'Cerrar',
            })
            resultMessage=false;
            }
         })
         return resultMessage;
   }
}

 export default SweetAlertModal;